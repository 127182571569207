import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import('@/pages/index/index.vue'),
  },
  
  {
    path: "/hosting",
    name: "hosting",
    component: () => import('@/pages/index/index-hosting.vue'),
  },
  {
    path: "/services",
    name: "services",
    component: () => import('@/pages/index/index-services.vue'),
  },
 
  {
    path: "/contact",
    name: "contact",
    component: () => import('@/pages/contact/contact-detail.vue'),
  },
  {
    path: "/page-comingsoon",
    name: "page-comingsoon",
    component: () => import('@/pages/special-pages/page-comingsoon.vue'),
  },
  {
    path: "/page-maintenance",
    name: "page-maintenance",
    component: () => import('@/pages/special-pages/page-maintenance.vue'),
  },
  {
    path: "/page-thankyou",
    name: "page-thankyou",
    component: () => import('@/pages/special-pages/page-thankyou.vue'),
  },
  {
    path: "/helpcenter",
    name: "helpcenter",
    component: () => import('@/pages/helpcenter/helpcenter.vue'),
  },
  {
    path: "/helpcenter-faqs",
    name: "helpcenter-faqs",
    component: () => import('@/pages/helpcenter/helpcenter-faqs.vue'),
  },
  {
    path: "/helpcenter-guides",
    name: "helpcenter-guides",
    component: () => import('@/pages/helpcenter/helpcenter-guides.vue'),
  },
  {
    path: "/helpcenter-support",
    name: "helpcenter-support",
    component: () => import('@/pages/helpcenter/helpcenter-support.vue'),
  },
  {
    path: "/email-confirmation",
    name: "email-confirmation",
    component: () => import('@/pages/email-template/email-confirmation.vue'),
  },
  {
    path: "/email-password-reset",
    name: "email-password-reset",
    component: () => import('@/pages/email-template/email-password-reset.vue'),
  },
  {
    path: "/email-alert",
    name: "email-alert",
    component: () => import('@/pages/email-template/email-alert.vue'),
  },
  {
    path: "/email-invoice",
    name: "email-invoice",
    component: () => import('@/pages/email-template/email-invoice.vue'),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import('@/pages/utility/terms.vue'),
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import('@/pages/utility/imprint.vue'),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import('@/pages/utility/privacy.vue'),
  },
  {
    path: "/ui-components",
    name: "ui-components",
    component: () => import('@/pages/docs/ui-components.vue'),
  },
  {
    path: "/documentation",
    name: "documentation",
    component: () => import('@/pages/docs/documentation.vue'),
  },
  {
    path: "/changelog",
    name: "changelog",
    component: () => import('@/pages/docs/changelog.vue'),
  },
  
  {
    path: "/:catchAll(.*)",
    name: "Error",
    component: () => import('@/pages/special-pages/page-error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;